import $ from 'jquery';
import { gsap } from 'gsap';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

function autoHeightAnimate(element, time) {
  const curHeight = element.height(); // Get Default Height
  const autoHeight = element.css('height', 'auto').height(); // Get Auto Height
  element.height(curHeight); // Reset to Default Height
  element.stop().animate({ height: autoHeight }, time); // Animate to Auto Height
}

class ModuleContact {
  init() {
    this.initListener();
    this.initParallax();
    this.onResize();
  }

  initListener = () => {
    $('.contact_phone_link').click(function () {
      const contactPhoneInfo = $('.contact_phone_info');
      const contactMailInfo = $('.contact_mail_info');
      const contactOfficeInfo = $('.contact_office_info');
      const animateTime = 500;
      if (contactPhoneInfo.height() === 0) {
        autoHeightAnimate(contactPhoneInfo, animateTime);
      } else {
        contactPhoneInfo.stop().animate({ height: '0' }, animateTime);
      }

      contactMailInfo.stop().animate({ height: '0' }, animateTime);
      contactOfficeInfo.stop().animate({ height: '0' }, animateTime);
    });

    $('.contact_mail_link').click(function () {
      const contactPhoneInfo = $('.contact_phone_info');
      const contactMailInfo = $('.contact_mail_info');
      const contactOfficeInfo = $('.contact_office_info');
      const animateTime = 500;
      if (contactMailInfo.height() === 0) {
        autoHeightAnimate(contactMailInfo, animateTime);
      } else {
        contactMailInfo.stop().animate({ height: '0' }, animateTime);
      }

      contactPhoneInfo.stop().animate({ height: '0' }, animateTime);
      contactOfficeInfo.stop().animate({ height: '0' }, animateTime);
    });

    $('.contact_office_link').click(function () {
      const contactPhoneInfo = $('.contact_phone_info');
      const contactMailInfo = $('.contact_mail_info');
      const contactOfficeInfo = $('.contact_office_info');
      const animateTime = 500;
      if (contactOfficeInfo.height() === 0) {
        autoHeightAnimate(contactOfficeInfo, animateTime);
      } else {
        contactOfficeInfo.stop().animate({ height: '0' }, animateTime);
      }

      contactPhoneInfo.stop().animate({ height: '0' }, animateTime);
      contactMailInfo.stop().animate({ height: '0' }, animateTime);
    });
  };

  initParallax = () => {
    customAnmation.animateCopy($('.contact_headline_01'));

    customAnmation.animateLink($('.contact_phone_link'));
    customAnmation.animateLink($('.contact_mail_link'));
    customAnmation.animateLink($('.contact_linkedin_link'));
    customAnmation.animateLink($('.contact_office_link'));
  };

  onResize = () => {
    $(window)
      .resize(function () {
        $('.contact_phone_info').stop().animate({ height: '0' }, 0);
        $('.contact_mail_info').stop().animate({ height: '0' }, 0);
        $('.contact_office_info').stop().animate({ height: '0' }, 0);
      })
      .resize();
  };
}

export default ModuleContact;
