import $ from 'jquery';
import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { GSDevTools } from 'gsap/GSDevTools';

class CustomAnimation {
  init() {
  }

  animateHeaderArrow () {
    gsap.to($('.header_arrow_container'), {
      scrollTrigger: {
        trigger: $('.header_arrow_container'),
        start: 'top 80%',
        end: 'top 70%',
        markers: false,
        scrub: 0
      },
      autoAlpha: 0
    });
  }

  animateHeadline(element, distance) {
    const animation = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top 100%;',
        end: 'top 80%;',
        scrub: 0,
        markers: false,
        pin: false
      }
    });

    const config = {
      opacity: 1,
      y: distance,
      stagger: {
        each: 0.05
      }
    };

    gsap.set(element, { autoAlpha: 1 });
    const split1 = new SplitText(element, { type: 'chars' });
    animation.from(split1.chars, config);

    //GSDevTools.create({ animation: animation });
  }

  animateHeadlineMobile(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 110%',
        end: 'center 80%',
        markers: false,
        scrub: 0
      },
      y: 50
    });
  }

  animateCopy(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 100%',
        end: 'center 80%',
        markers: false,
        scrub: 0
      },
      y: 50
    });
  }

  animateLink(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 100%',
        end: 'top 80%',
        markers: false,
        scrub: 0
      },
      alpha: 0
    });
  }

  animateServiceElement(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 100%',
        end: 'center 80%',
        markers: false,
        scrub: 0
      },
      scale: 0.8
    });
  }

  animateFormElement(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 100%',
        end: 'center 80%',
        markers: false,
        scrub: 0
      },
      scale: 0.95
    });
  }

  animateSubline(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 100%',
        end: 'center 80%',
        markers: false,
        scrub: 0
      },
      y: 50,
      autoAlpha: 0
    });
  }

  animateGroup(elements) {
    const elementGroup = gsap.utils.toArray(elements);
    elementGroup.forEach((element) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 110%',
          end: 'top 65%',
          markers: false,
          scrub: 0
        },
        y: 35
      });
    });
  }
}

export default CustomAnimation;
