import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();
let timeout;

function updateHeight() {
  console.log('updateHeight');
  clearTimeout(timeout);
  $('.teamed_images').css('height', 0);
  const { scrollHeight } = $('.teamed_images')[0];
  $('.teamed_images').css('height', `${scrollHeight}px`);
  $('.teamed_images').css('min-height', $('.teamed_image_container', this).outerHeight());
}

class ModuleTeamed {
  init() {
    this.initParallax();
    this.onResize();
    this.onScroll();

    timeout = setTimeout(function () {
      // updateHeight();
    }, 1000);
  }

  initParallax = () => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.saveStyles(
      '.teamed_headline',
      'teamed_image_01',
      'teamed_image_02',
      'teamed_image_03',
      'teamed_image_04',
      'teamed_image_05',
      'teamed_image_06',
      'teamed_image_07',
      'teamed_image_08',
      'teamed_image_09',
      'teamed_image_10',
      'teamed_image_11',
      'teamed_image_12'
    );

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        customAnmation.animateHeadlineMobile($('.teamed_headline'), 50);
      },

      // mobile
      '(max-width: 767px)': function () {
        customAnmation.animateHeadlineMobile($('.teamed_headline'));
      }
    });

    gsap.from('.teamed_image_01', {
      scrollTrigger: {
        trigger: '.teamed_image_container_01',
        start: 'top 100%',
        end: 'top 50%',
        markers: false,
        scrub: 0
      },
      y: 50
    });
    gsap.from('.teamed_image_02', {
      scrollTrigger: {
        trigger: '.teamed_image_container_02',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: -40
    });
    gsap.from('.teamed_image_03', {
      scrollTrigger: {
        trigger: '.teamed_image_container_03',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: 50
    });
    gsap.from('.teamed_image_04', {
      scrollTrigger: {
        trigger: '.teamed_image_container_04',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: -50
    });
    gsap.from('.teamed_image_05', {
      scrollTrigger: {
        trigger: '.teamed_image_container_05',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: -60
    });
    gsap.from('.teamed_image_06', {
      scrollTrigger: {
        trigger: '.teamed_image_container_06',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: 80
    });
    gsap.from('.teamed_image_07', {
      scrollTrigger: {
        trigger: '.teamed_image_container_07',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: 50
    });
    gsap.from('.teamed_image_08', {
      scrollTrigger: {
        trigger: '.teamed_image_container_08',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: 30
    });
    gsap.from('.teamed_image_09', {
      scrollTrigger: {
        trigger: '.teamed_image_container_08',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: -60
    });
    gsap.from('.teamed_image_10', {
      scrollTrigger: {
        trigger: '.teamed_image_container_08',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: -80
    });
    gsap.from('.teamed_image_11', {
      scrollTrigger: {
        trigger: '.teamed_image_container_08',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: 60
    });
    gsap.from('.teamed_image_12', {
      scrollTrigger: {
        trigger: '.teamed_image_container_12',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: 60
    });
  };

  onResize = () => {
    $(window)
      .resize(function () {
        updateHeight();
      })
      .resize();
  };

  onScroll = () => {
    $(window)
      .resize(function () {
        updateHeight();
      })
      .scroll();
  };
}

export default ModuleTeamed;
