import $ from 'jquery';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

function startRocket() {
  console.log('startRocket');

    setTimeout(function () {
        let string = '';
        string += "<div class='rocket'>";
        string += "<img src='images/Rakete_small_2.gif'>";
        string += '</div>';
        $('.rocket_holder').append(string);
        var randomX = gsap.utils.random(50, $(window).width()-50);
        $('.rocket').css('left', randomX+'px');
        $('.rocket').addClass('show');
    }, 500);
}

function destroyRockt() {
    $(".rocket").fadeOut(500, function() {
        $(this).remove();
    });
}

class ModuleRocket {
  init() {
    this.initParallax();
    this.initListener();
  }

  initListener = () => {
    $('.rocket_holder').click(function () {
      destroyRockt();
    });

    const map = {
      90: false,
      74: false
    };
    $(document)
      .keydown(function (e) {
        if (e.keyCode in map) {
          map[e.keyCode] = true;
          if (map[90] && map[74]) {
            // FIRE EVENT
            // alert("EASTER EGG");
             destroyRockt();
            startRocket();

            if (e.keyCode in map) {
              map[e.keyCode] = false;
            }
          }
        }
      })
      .keyup(function (e) {
        if (e.keyCode in map) {
          map[e.keyCode] = false;
        }
      });
  };

  initParallax = () => {
    gsap.registerPlugin(MotionPathPlugin);
  };
}

export default ModuleRocket;
