import ModuleHeader from '../modules/moduleHeader';
import CustomAnimation from '../animation/customAnimation';
import ModuleAbout from '../modules/moduleAbout';
import ModuleService from '../modules/moduleService';
import ModuleContact from '../modules/moduleContact';
import ModuleRocket from '../modules/moduleRocket';
import ModuleIntro from '../modules/moduleIntro';
import ModuleTeamed from '../modules/moduleTeamed';

class Homepage {
  init() {
    const moduleRocket = new ModuleRocket();
    moduleRocket.init();

    const moduleIntro = new ModuleIntro();
    moduleIntro.init();

    const moduleHeader = new ModuleHeader();
    moduleHeader.init();

    const moduleAbout = new ModuleAbout();
    moduleAbout.init();

    const moduleService = new ModuleService();
    moduleService.init();

    const moduleTeamed = new ModuleTeamed();
    moduleTeamed.init();

    const moduleContact = new ModuleContact();
    moduleContact.init();

    const customAnimation = new CustomAnimation();
    customAnimation.init();
  }
}

export default Homepage;
