import $ from 'jquery';

let naviIsOpen = false;

function openNavigation() {
  $('#toggle').addClass('active');
  $('#mainNav').addClass('open');
  $('.logo .white').addClass('show');
  $('.logo .black').removeClass('show');

  naviIsOpen = true;
}

function closeNavigation() {
  $('#mainNav').addClass('closing');

  setTimeout(function () {
    $('#mainNav').removeClass('open');

    $('.logo .white').removeClass('show');
    $('.logo .black').addClass('show');
    $('#toggle').removeClass('active');

    naviIsOpen = false;
  }, 500);

  setTimeout(function () {
    $('#mainNav').removeClass('closing');
  }, 1000);
}

$.fn.isInViewport = function () {
  const elementTop = $(this).offset().top;
  const elementBottom = elementTop + $(this).outerHeight();

  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + window.innerHeight; // <-- here

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

class ModuleNavigation {
  init() {
    this.show();
    this.initListener();
    this.onScroll();
    // this.onResize();
  }

  show = () => {
    setTimeout(function () {
      $('.navbar')
        // .delay(1500)
        .queue(function () {
          $(this).addClass('show');
          $('#toggle').addClass('show');
        });
    }, 1000);

    setTimeout(function () {
      $('.burger_top').addClass('animate_100');
    }, 1500);

    setTimeout(function () {
      $('.burger_middle').addClass('animate_100');
    }, 1700);

    setTimeout(function () {
      $('.burger_bottom').addClass('animate_100');
    }, 1900);
  };

  initListener = () => {
    $('#toggle').click(function () {
      if (naviIsOpen === false) {
        openNavigation();
      } else {
        closeNavigation();
      }
    });

    $('#mainNav').click(function () {
      closeNavigation();
    });

    $('.menu_item').click(function () {
      closeNavigation();
    });
    $('.menu_item_scroll').click(function (e) {
      e.preventDefault();
      const aid = $(this).attr('href');
      $('html,body').animate({ scrollTop: $(aid).offset().top }, 500);
    });
  };

  onScroll = () => {
    $(window)
      .scroll(function () {
        //$('#toggle').removeClass('black');

        // header
        if ($('#header').isInViewport()) {
          //console.log('header');
          //$('#toggle').removeClass('black');
        }

        // about
        if ($('#about').isInViewport()) {
          //console.log('about');
          //$('#toggle').addClass('black');
        }

        // service
        if (!$('#service').isInViewport()) {
          //console.log('service');
          //$('#toggle').addClass('black');
        }

        // teamed
        if (!$('#teamed').isInViewport()) {
          //console.log('teamed');
          //$('#toggle').removeClass('black');
        }

        // contact
        if (!$('#contact').isInViewport()) {
          //console.log('contact');
          //$('#toggle').removeClass('black');
        }
      })
      .scroll();
  };
}

export default ModuleNavigation;
