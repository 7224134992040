import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Slick from 'slick-carousel';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();
let slick;

class ModuleService {
  init() {
    this.initListener();
    this.initSlick();
    this.initParallax();
    this.onResize();
  }

  initListener = () => {
    $('.menu_item').click(function () {
      $('.service_carossel').slick('slickGoTo', 0);
    });
  };

  initSlick = () => {
    slick = $('.service_carossel').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  };

  initParallax = () => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.saveStyles(
      '.service_headline',
      '.service_slide',
      'service_icon',
      'service_text',
      'service_carossel'
    );

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        customAnmation.animateHeadlineMobile($('.service_headline'), 50);
        customAnmation.animateServiceElement('.service_slide');
      },

      // mobile
      '(max-width: 767px)': function () {
        customAnmation.animateHeadlineMobile($('.service_headline'));
        customAnmation.animateServiceElement($('.service_carossel'));
      }
    });
  };

  onResize = () => {
    $(window)
      .resize(function () {})
      .resize();
  };
}

export default ModuleService;
