import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CustomAnimation from '../animation/customAnimation';

class ModuleHeader {
  init() {
    //this.checkHashtag();
    this.animate();
    this.initParallax();
  }

  checkHashtag = () => {
    if (window.location.hash === '') {
      $(window).scrollTop(0);
    } else if ($(window.location.hash).offset().top !== undefined) {
      $(window).scrollTop($(window.location.hash).offset().top);
    }
  };

  animate = () => {
    setTimeout(function () {
      $('.header_image_holder').addClass('show');
    }, 2500);

    setTimeout(function () {
      $('.header_arrow_holder').addClass('show');
    }, 5000);
  };

  initParallax = () => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.saveStyles('.header_image');

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        gsap.to('.header_image', {
          scrollTrigger: {
            trigger: '#header',
            start: 'center 50%',
            end: 'bottom 0%',
            markers: false,
            scrub: 0
          },
          y: 150,
          scale: 1.1
        });

        gsap.from('.header_text_holder', {
          scrollTrigger: {
            trigger: '.header_text_holder',
            start: 'top 100%',
            end: 'center 60%',
            markers: false,
            scrub: 0
          },
          y: 100
        });
        gsap.from('.header_logo_holder', {
          scrollTrigger: {
            trigger: '.header_logo_holder',
            start: 'top 100%',
            end: 'center 60%',
            markers: false,
            scrub: 0
          },
          scale: 0.9
        });
      },

      // mobile
      '(max-width: 767px)': function () {
        gsap.to('.header_image', {
          scrollTrigger: {
            trigger: '#header',
            start: 'center 50%',
            end: 'bottom 0%',
            markers: false,
            scrub: 0
          },
          scale: 1.1
        });
      }
    });

    /*
    const getRatio = (el) => window.innerHeight / (window.innerHeight + el.offsetHeight);

    gsap.utils.toArray('section').forEach((section, i) => {
      section.bg = section.querySelector('.bg_');

      // the first image (i === 0) should be handled differently because it should start at the very top.
      // use function-based values in order to keep things responsive
      gsap.fromTo(
        section.bg,
        {
          backgroundPosition: () =>
            i ? `50% ${-window.innerHeight * getRatio(section)}px` : '50% 0px'
        },
        {
          backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section))}px`,
          ease: 'none',
          scrollTrigger: {
            trigger: section,
            start: () => (i ? 'top bottom' : 'top top'),
            end: 'bottom top',
            scrub: true,
            invalidateOnRefresh: true // to make it responsive
          }
        }
      );
    }); */
  };
}

export default ModuleHeader;
