import $ from 'jquery';
import {gsap} from "gsap";
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class ModuleAbout {
  init() {
    this.initParallax();
  }

  initParallax = () => {
    customAnmation.animateGroup($('.about_copy'));
  };
}

export default ModuleAbout;
