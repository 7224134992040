import $ from 'jquery';
import { gsap, TimelineLite } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import Flickity from 'flickity';

const mySplitText1 = [
  $('.intro_letter_01'),
  $('.intro_letter_02'),
  $('.intro_letter_03'),
  $('.intro_letter_04'),
  $('.intro_letter_05'),
  $('.intro_letter_06'),
  $('.intro_letter_07'),
  $('.intro_letter_08'),
  $('.intro_letter_09'),
  $('.intro_letter_10'),
  $('.intro_letter_11'),
  $('.intro_letter_12'),
  $('.intro_letter_13'),
  $('.intro_letter_14'),
  $('.intro_letter_15')
];
const mySplitText2 = new SplitText('#intro_text_02', { type: 'chars, words' });

class ModuleIntro {
  init() {
    gsap.registerPlugin(SplitText, ScrollTrigger);

    $('.scroll_arrow').click(function () {
      $('html, body').animate(
        {
          scrollTop: $($.attr(this, 'href')).offset().top
        },
        4000
      );
    });

    /*
    $(document).on('click', 'a[href^="#"]', function (event) {
      event.preventDefault();

      $('html, body').animate(
        {
          scrollTop: $($.attr(this, 'href')).offset().top
        },
        3000
      );
    });
*/

    this.animate2();
    this.initParallax();
    this.onResize();
  }

  animate2 = () => {
    setTimeout(function () {
      $('#intro_text_01').addClass('show');
      $('#intro_text_02').addClass('show');

      const tl1 = new TimelineLite();
      const tl2 = new TimelineLite();
      const numChars1 = mySplitText1.length;
      const numChars2 = mySplitText2.chars.length;

      for (let i = 0; i < numChars1; i += 1) {
        if (i !== 8 && i !== 11) {
          tl1.to(mySplitText1[i], 2, { opacity: 1 }, Math.random() * 1.1);
        }
      }
      for (let i = 0; i < numChars2; i += 1) {
        tl2.from(mySplitText2.chars[i], 2, { opacity: 0 }, Math.random() * 1.1);
      }
    }, 550);
  };

  initParallax = () => {
    gsap.to('.intro', {
      scrollTrigger: {
        trigger: '.intro',
        start: 'bottom 100%',
        end: 'bottom 50%',
        markers: false,
        scrub: true,
        pin: '.intro'
      }
    });
    gsap.to('.intro_letter_09', {
      scrollTrigger: {
        trigger: '.intro_letter_09',
        start: 'bottom 50%',
        end: 'bottom 0%',
        markers: false,
        scrub: true
      },
      opacity: 1
    });
    gsap.to('.intro_letter_12', {
      scrollTrigger: {
        trigger: '.intro_letter_12',
        start: 'bottom 40%',
        end: 'bottom 0%',
        markers: false,
        scrub: true
      },
      opacity: 1
    });
    gsap.to('.intro_text_container_02', {
      scrollTrigger: {
        trigger: '.intro_text_container_02',
        start: 'bottom 60%',
        end: 'bottom 0%',
        markers: false,
        scrub: true
      },
      opacity: 0
    });
    gsap.to('.intro_logo', {
      scrollTrigger: {
        trigger: '.intro_logo',
        start: 'bottom 20%',
        end: 'bottom 20%',
        markers: false,
        scrub: true
      },
      opacity: 1
    });
    gsap.from('.intro_logo', {
      scrollTrigger: {
        trigger: '.intro_logo',
        start: 'bottom 20%',
        end: 'bottom -30%',
        markers: false,
        scrub: true
      },
      scale: 2
    });
  };

  onResize = () => {
    $(window)
      .resize(function () {
        const winWidth = $(window).width();
        $('.debug').text('XS');
        if (winWidth > 576) {
          $('.debug').text('SM');
        }
        if (winWidth > 768) {
          $('.debug').text('MD');
        }
        if (winWidth > 992) {
          $('.debug').text('LG');
        }
        if (winWidth > 1200) {
          $('.debug').text('XL');
        }
        if (winWidth > 1400) {
          $('.debug').text('XXL');
        }
      })
      .resize();
  };
}

export default ModuleIntro;
